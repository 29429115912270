<template>
  <div v-if="showNavigation" class="handle-area flex items-center justify-center px-[2rem] pt-[1rem]">
    <button v-if="showNavigationButtons" class="translate-x-[-50%] py-4 pl-4 pr-2" type="button" @click="scrollLeftButtonClick">
      <svg class="h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z"/></svg>
    </button>
    <div ref="handleContainer" class="relative flex h-[2px] w-full max-w-[300px] rounded-full bg-[#D9D9D9]">
      <div ref="handle" class="absolute top-0 h-full w-1/6 rounded-full bg-[#222222]" style="left:0;"></div>
    </div>
    <button v-if="showNavigationButtons" type="button" class="slide-right flex translate-x-[53%] justify-end py-4 pl-2 pr-4" @click="scrollRightButtonClick">
      <svg class="h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/></svg>
    </button>
  </div>
</template>

<script setup>
  import { nextTick, ref, toRefs } from 'vue'

  const scrollTolerance = 50;

  const props = defineProps({
    showNavigation: {
      type: Boolean,
      required: false,
      default: true
    },
    showNavigationButtons: {
      type: Boolean,
      required: false,
      default: true
    },
    sliderId: {
      type: String,
      required: true,
    },
    autoScroll: {
      type: Boolean,
      required: false,
      default: false
    },
  });

  const { showNavigationButtons } = toRefs(props);
  let showNavigation = ref(props.showNavigation);

  let autoScrollInterval;
  let isAutoScroll = false;
  let isUpdateCssElementValuesPending = false;

  const slider = document.getElementById(props.sliderId);
  let singleElementWidth = slider.children[0].clientWidth ?? 20;
  let maxScroll = slider.scrollWidth - slider.clientWidth;

  const handleContainer = ref(null);
  const handle = ref(null);

  window.addEventListener("resize", updateCssElementValuesThrottled);

  updateCssElementValues();

  slider.addEventListener('scroll', () => {
    if (!isAutoScroll) {
      clearInterval(autoScrollInterval);
    }

    // Add a delay before setting isAutoScroll to false
    setTimeout(() => {
      isAutoScroll = false;
    }, 2000);

    updateSliderPosition();
  });


  function scrollRightButtonClick(){
    clearInterval(autoScrollInterval);
    scrollRight();
  }

  function scrollRight(width = singleElementWidth) {

    if (slider.scrollLeft + slider.clientWidth + scrollTolerance >= slider.scrollWidth) {
      // If the slider is at the end, reset scrollLeft to 0
      slider.scrollTo({
        left: 0,
        behavior: 'smooth'
      });
    } else {
      // Otherwise, scroll to the next element
      slider.scrollTo({
        left: slider.scrollLeft + width,
        behavior: 'smooth'
      });
    }
  }

  function scrollLeftButtonClick(){
    clearInterval(autoScrollInterval);
    scrollLeft();
  }
  function scrollLeft(width = singleElementWidth) {
    if (slider.scrollLeft <= scrollTolerance) {
      slider.scrollTo({
        left: slider.scrollWidth,
        behavior: 'smooth'
      });
    } else {
      slider.scrollTo({
        left: slider.scrollLeft - width,
        behavior: 'smooth'
      });
    }
  }

  function updateSliderPosition() {
    // Calculate the current scroll position as a percentage
    const scrollPercentage = Math.min(1, Math.max(0, slider.scrollLeft / maxScroll));

    // Set the left style property of the handle
    if (handleContainer.value) {
      const left = scrollPercentage * (handleContainer.value.offsetWidth - handle.value.offsetWidth);

      handle.value.style.left = `${left}px`;
    }
  }

  function updateCssElementValuesThrottled() {
    if (isUpdateCssElementValuesPending) return;

    isUpdateCssElementValuesPending = true;

    setTimeout(() => {
      updateCssElementValues();
      isUpdateCssElementValuesPending = false;
    }, 500);
  }

  function updateCssElementValues() {
    if (!slider) {
      return;
    }
    singleElementWidth = slider?.children[0]?.clientWidth ?? 20;
    maxScroll = slider.scrollWidth - slider.clientWidth;

    nextTick(() => {
      updateSliderPosition();
    })

    clearInterval(autoScrollInterval);

    if (maxScroll < 5) {
      showNavigation.value = false;
    } else if (maxScroll > singleElementWidth && props.autoScroll && !navigator.userAgent.toLowerCase().includes('firefox')) {
      //Disable auto scroll on firefox, due to lack of support
      showNavigation.value = props.showNavigation;
      autoScrollInterval = setInterval(() => {
        scrollRight()
        isAutoScroll = true;
      }, 3000);
    } else {
      showNavigation.value = props.showNavigation;
    }
  }
</script>